/* src/index.css */

/* You can add global styles here */

body {
  background-color: #f0f2f5;
}

h1 {
  text-align: center;
}
